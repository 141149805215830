import React from 'react';
import { Container, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Accordion, Divider, Segment, Label, Menu } from 'semantic-ui-react'

import AuthenticateMenu from '../libraries/auth/AuthenticateMenu'
import SigninForm from '../libraries/auth/SigninForm'
import SignupForm from '../libraries/auth/SignupForm';
import PasswordForm from '../libraries/auth/PasswordForm';

class AuthenticateBody extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      key: 'signin'
    }
  }

  openMenu(key) {
    this.setState({
      key: key
    })
  }
  
  render() {
    const { key }= this.state
    const { screenHeight }= this.props

    return(<>
      <div style={{minHeight: screenHeight}}>
        {/* <Container style={{border: 'none'}}>
          <div style={{textAlign: 'center'}}>
            <AuthenticateMenu openMenu={this.openMenu.bind(this)} menu= {this.state.key}/>
          </div>
        </Container> */}
        <Container fluid style={{padding: '7em 0 1em 0', background: 'rgb(255 255 255 / 2%)'}}>
          {key==='signin' && <SigninForm screenHeight={this.props.screenHeight} screenWidth={this.props.screenWidth} openMenu={this.openMenu.bind(this)} />}
          {key==='signup' && <SignupForm screenHeight={this.props.screenHeight} screenWidth={this.props.screenWidth} openMenu={this.openMenu.bind(this)} />}
          {key==='password' && <PasswordForm screenHeight={this.props.screenHeight} screenWidth={this.props.screenWidth} openMenu={this.openMenu.bind(this)} />}

          <div style={{textAlign: 'center', width: '450px', margin: 'auto'}}>
            <Divider hidden/>
            <Header as='h4'>
              <b>MD Media!</b> Digital Communication 2.3.01
              <Header.Subheader style={{color: '#222'}}>
                Trademarks and Copyrights by <b>@MD Media!</b> 2023
              </Header.Subheader>
            </Header>
          </div>
        </Container>
      </div>
    </>);
  }
} export default AuthenticateBody
